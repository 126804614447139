import RepoUtils from "./Request/RepoUtils";

export default {
  // GET
  obtenerMarcas: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "articulosMarcas", "listarGestor"),

  // POST
  agregarMarca: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "articulosMarcas", "agregar"),
  editarMarca: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "articulosMarcas", "editar"),
  eliminarMarca: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "articulosMarcas", "eliminar"),
};
